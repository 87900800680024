<template>
  <validation-provider
    #default="{ errors }"
    :rules="invalid ? 'objectRequired' : ''"
    :name="title"
  >
    <v-select
      :id="id"
      :ref="`${id}Select`"
      v-model="valueSelected"
      :options="itens"
      class="select-base-clearable"
      :class="[selectClass, {'is-select-invalid': errors && errors.length}]"
      :label="label"
      :clearable="!require"
      :disabled="disabled"
      @open="carregarItens"
      @search-change="carregarItensFiltrados"
      @input="enviarPai"
    />
    <small
      v-if=" errors && errors.length > 0"
      class="text-danger"
    >{{ errors[0] }}</small>
  </validation-provider>
</template>

<style lang="scss">
.is-select-invalid > .vs__dropdown-toggle {
  border-color: #ea5455 !important;
  background-repeat: no-repeat !important;
  background-size: calc(0.725em + 0.438rem) calc(0.725em + 0.438rem) !important;
}

.select-base-clearable .vs__dropdown-option:first-child {
  padding:1rem;
}

.vs__open-indicator {
  fill: rgba(60,60,60,.5)!important;
  transition: transform .15s cubic-bezier(1,-.115,.975,.855)!important;
}
</style>

<script>
import vSelect from 'vue-select'
import '@core/scss/vue/libs/vue-select.scss'
import { ValidationProvider } from 'vee-validate'
import { defineComponent, ref, watch } from '@vue/composition-api'
import { objectRequired } from '@pilar/plugins/p-validation/p-validation'
import useSelectServer from './selectServer'

export default defineComponent({
  components: {
    ValidationProvider,
    vSelect,
  },
  props: {
    value: {
      type: Object,
      default: null,
    },
    id: {
      type: [String, Number],
      required: true,
    },
    invalid: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
    selectClass: {
      type: String,
      default: '',
    },
    filtros: {
      type: Array,
      default: () => [],
    },
    request: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      objectRequired,
    }
  },
  setup(props, { emit }) {
    const valueSelected = ref(null)
    const itens = ref([])

    valueSelected.value = props.value
    watch(() => props.value, val => {
      valueSelected.value = val
    })

    const {
      carregarItens,
      carregarItensFiltrados,
      carregando,
    } = useSelectServer({ props, itens })

    const enviarPai = () => {
      emit('input', valueSelected.value)
    }

    return {
      carregando,
      carregarItens,
      carregarItensFiltrados,
      itens,
      valueSelected,
      enviarPai,
    }
  },
})
</script>
