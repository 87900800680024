/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */

import { ref } from '@vue/composition-api'
import Vue from 'vue'

export default function useSelectServer({ props, itens }) {
  const carregando = ref(false)
  const acessado = ref(false)

  /**
    * Carregar os itens do select trazendo do backend
    */
  const carregarItens = () => {
    if (!acessado.value && !carregando.value) {
      carregando.value = true
      props.request(props.filtros)
        .then(payload => {
          itens.value = [...payload.data]
          // this.filtraItens(payload.data)
          acessado.value = false
          carregando.value = false
        })
        .catch(err => {
          Vue.$errorFormated(err.response)
          carregando.value = false
          throw err
        })
    }
    // else {
    //   this.itens = [...this.itensCarregados]
    // this.filtraItens(this.itens)
    // }
  }

  /**
   * Carregar os itens do select filtrando pela busca feita pelo usuario
   */
  const carregarItensFiltrados = digitado => {
    if (!carregando.value) {
      const novoFiltro = props.filtros.filter(fil => fil.soletrado)
      novoFiltro.forEach(each => {
        each.valor = digitado
      })
      carregando.value = true
      props.request(novoFiltro)
        .then(payload => {
          itens.value = [...payload.data]
          //   this.filtraItens(payload.data)
          carregando.value = false
        })
        .catch(err => {
          carregando.value = false
          Vue.$errorFormated(err.response)
          throw err
        })
    }
  }

  return {
    carregando,
    carregarItens,
    carregarItensFiltrados,
  }

  // export const filtraItens = itensCarregados => {
  //   const itensFiltroAdicional = this.filtroAdicional(itensCarregados)
  //   this.itens = itensFiltroAdicional !== null ? itensFiltroAdicional : itensCarregados

  //   if (this.clearable && this.itens.length > 0) {
  //     this.adicionaOpcaoVazia(itens)
  //   }
  // }

// export const adicionaOpcaoVazia = itens => itens.unshift({ value: null, text: '' })
}
